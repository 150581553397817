.CC-mainHeader{
    background-color: #151921;
    .CC-mainHeader-left{
        flex: 1;
    }
}
.CC-mainHeader__container{
    display: flex;
    padding: 12px 10px;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    .CC-mainHeader-left{
        padding-right: 10px;
    }
    .CC-brandLogo{
        width: 280px;
        @media screen and #{$CC-mediaQueryRange-tabletDevice} {
            width: 140px;
        }
        @media screen and #{$CC-mediaQueryRange-mobileDevice} {
            width: 120px;
        }
    }
}
.CC-mainHeader__sticky{
    position: sticky;
    top: 0;
    z-index: 99999;
}