.CC-superBanner{
    //padding: 0 $CC-boundaryLRSpace;
    //height: 400px;
    overflow: hidden;
    margin: 0;
    // border-radius: $border-radius;
    .CC-container{
        width: 100%;
    }
    @media screen and #{$CC-mediaQueryRange-mobileLayout} {
        //height: 130px;
        height: auto;
        //margin-top: 12px;
    }
    .CC-sectionHeading{
        text-transform: inherit;
        font-family: "Oswald",sans-serif;
        margin-bottom: 20px;
        @media screen and (max-width: 512px) {
            font-size: 35px;
            line-height: 40px;
        }
    }
    .CC-sectionDesc{
        color: $black;
        font-weight: 300;
        padding-bottom: 20px;
        @media screen and (max-width: 512px) {
            line-height: 25px;
        }
        @media screen and #{$CC-mediaQueryRange-desktopDevice} {
            padding-bottom: 50px
        }
    }
    .CC-btnBanner{
        font-weight: 400;
        padding: 0px 30px;
        padding-bottom: 2px;
        margin-bottom: 10px;
        @media screen and #{$CC-mediaQueryRange-desktopDevice} {
            margin-bottom: 0;
        }
    }
    .CC-superBanner-item{
        position: relative;
        width: 100%;
        // min-width: 300px;
        // max-width: 320px;
        //border-radius: 6px;
        overflow: hidden;
        color: #FFF;
        background-color: #333;
        display: flex;
        align-items: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @media screen and #{$CC-mediaQueryRange-desktopDevice} {
            height: 450px;
            background-position: center right;
        }
        @media screen and #{$CC-mediaQueryRange-mobileDevice} {
            align-items: flex-start;
        }
        @media screen and #{$CC-mediaQueryRange-tabletDevice} {
            align-items: flex-start;
        }
        .CC-txt-dark{
            color: #000;
        }
        .CC-txt-white{
            color: #FFF;
        }
        .CC-superBanner-image{
            text-align: right;
            img{
                height: 120px;
            }
        }
        .CC-superBanner-content{
            // position: absolute;
            // top: 0;
            // right: 0;
            // bottom: 0;
            // left: 0;  
            padding: 12px;  
            flex: 1 1 auto;
            max-width: 60%;  
            
            @media screen and #{$CC-mediaQueryRange-mobileDevice} {
                max-width: 100%;  
            }   
        }        
        .CC-banner-textSmall{
            font-size: 12px;
            @media screen and #{$CC-mediaQueryRange-desktopDevice} {
                font-size: 16px;
            }
        }
        .CC-banner-textMedium{
            font-size: 16px;
            font-weight: bold;
            line-height: 1;
            @media screen and #{$CC-mediaQueryRange-desktopDevice} {
                font-size: 20px;
            }
        }
        .CC-banner-textBig{
            font-size: 20px;
            font-weight: bold;
            line-height: 1;
            @media screen and #{$CC-mediaQueryRange-desktopDevice} {
                font-size: 24px;
            }
        }
    }
    // ul{
    //     display: flex;
    //     li{
    //         //margin-right: 10px;
    //         background-color: #EEE;
    //         //border-radius: 6px;
    //         overflow: hidden;
    //         width: 100%;
    //         flex: 1 1 100%;
    //     }
    // }
    // .splide__progress{
    //     // position: relative;
    //     // top: -3px;
    //     .splide__progress__bar{
    //         background-color: $brand-secondaryColor;
    //         border-radius: 3px;
    //     }
    // }
    &.CC-accountVerifyBanner{
        .CC-superBanner-item{
            background-image: url('#{$images-path}/banners/account-bg.png');
            background-size: auto;
            color: #000;
            background-color: #fafafa;

            @media screen and #{$CC-mediaQueryRange-mobileDevice} {
                // font-size: 36px;
            }
        }
    }
    &.CC-promo2023Banner{
        background-image: url('#{$images-path}/CCbackground.jpg');
        background-size: auto;

        .CC-superBanner-item{
            background-image: url('#{$images-path}/banners/game-hand.png');
            background-size: contain;
            background-position: right bottom;
            background-repeat: no-repeat;
            background-color: transparent;
            color: #fff;
            border-top: 5px solid $brand-secondaryColor;
            border-bottom: 5px solid $brand-secondaryColor;
            height: auto;
            @media screen and #{$CC-mediaQueryRange-mobileDevice} {
                height: 750px;
            }
        }
        .CC-sectionDescPromo{
            color: #fff;
            font-weight: 300;
        }
        .CC-sectionDescPromoList{
            color: $brand-secondaryColor;
            font-weight: 400;
        }
        .CC-20top{
            padding-top: 20px;
        }
        .CC-20btm{
            padding-bottom: 20px;
        }
        .CC-40btm{
            padding-bottom: 40px;
        }
    }
}
.CC-section__header{
    font-size: 40px;
    color: #1c1f26;
    font-weight: 600;
    padding-bottom: 10px;
}
.CC-section{
    padding: 24px 10px;
    @media screen and #{$CC-mediaQueryRange-desktopDevice} {
        padding: 5rem 10px;
    }
}
.CC-sectionHeading{
    font-size: 55px;
    font-weight: 500;
    text-transform: uppercase;
    color: $brand-secondaryColor;
    font-family: $headers-fontFamily;
    line-height: 1;
    margin-bottom: 8px;
    @media screen and #{$CC-mediaQueryRange-mobileDevice} {
        font-size: 36px;
    }
}
.CC-sectionDesc{
   font-weight: 200;
   color: #aaa;
   padding-bottom: 50px;
   @media screen and #{$CC-mediaQueryRange-mobileDevice} {
       padding-bottom: 20px;
       line-height: 1.3;
   }
}
.CC-section__content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    @media screen and #{$CC-mediaQueryRange-desktopDevice} {
        flex-direction: row;
        padding-bottom: 0;
   }
    .CC-section__contentLeft{
        flex: 1 1 50%;
        padding-right: 0;
        padding-bottom: 50px;
        
    @media screen and #{$CC-mediaQueryRange-desktopDevice} {
        padding-right: 50px;
        padding-bottom: 0;
     }
    }
    .CC-coinDetails{
        .CC-coinName{
            color: $brand-secondaryColor;
            font-size: 36px;
        }
        .CC-coinPrice{
            font-size: 55px;
            font-weight: 600;
            color: $black;
            margin: 20px 0 30px 0;
        }
    }
    .CC-text {
        font-size: 24px;
        margin: 18px 0px;
    }
}
.CC-dashboard{
    background-color: #1c1f26;
    .CC-section__content{
        padding-top: 20px;
        @media screen and #{$CC-mediaQueryRange-desktopDevice} {
            padding-top: 100px;
         }
    }
    .CC-timeFilters{
        padding-bottom: 30px;
        ul{
            display: flex;
            li{
                border: 1px solid #ccc;
                border-radius: 30px;
                background-color: transparent;
                padding: 8px 18px;
                color: #eaeaea;
                font-size: 16px;
                text-align: center;
                cursor: pointer;
                min-width: 86px;
                height: 42px;
                &:not(:last-child){
                    margin-right: 20px;
                }
                &.active{
                    background-color: $brand-secondaryColor;
                    color: $white;
                    border-color: $brand-secondaryColor;
                }
            }
        }
    }
}
.CC-buyBitCoins{
    background-color: $black;
    .CC-container{
        display: flex;
        justify-content: center;
    }
    .CC-buyBitCoins{
        font-size: 30px;
        font-weight: 500;
        width: 420px;
        padding: 0 20px;
        height: 72px;
        border-radius: 50px;
        @media screen and #{$CC-mediaQueryRange-mobileDevice} {
            margin-bottom: 10px;
            height: 60px;
            font-size: 28px;
        }
    }
}
.CC-buyCoins{
    background-color: $white;
    position: relative;
    &:after{
        content: "";
        background-image: linear-gradient(to right, rgba(0,0,0,0), #b09a51, rgba(0,0,0,0));
        height: 1px; /*for horizontal border; width for vertical*/
        display: block;
        position: absolute;
        bottom: 0;
        width: 50%;
        transform: translate(50%, 0);
    }

    .CC-sectionHeading{
        margin-bottom: 30px;
        img{
            margin-right: 20px;
        }
    }
    .CC-section__contentRight{
        align-self: stretch;
    }
    .CC-stakeAndBuycoinsBtns{
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-around;
        align-items: center;
        button{
            font-size: 30px;
            font-weight: 500;
            width: 420px;
            padding: 8px 20px;
            height: 72px;
            border-radius: 50px;
            margin-bottom: 10px;
            @media screen and #{$CC-mediaQueryRange-mobileDevice} {
                height: 60px;
                font-size: 28px;
                width: 100%;
            }
            @media screen and #{$CC-mediaQueryRange-desktopDevice} {
                margin-bottom: 0;
            }
        }
        .CC-buttonGroup {
            text-align: center;
        }
        .CC-buttonGroupText {
            color: $black;
            margin-top: 20px;
        }
    }
}

.CC-subscribe{
    background-color: #111;
    .CC-sectionHeading{
        font-family: $body-fontFamily;
        text-transform: inherit;
        font-weight: 300;
    }
    .CC-section__content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .CC-subscribeNow{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        .CC-form-control{
            margin-right: 10px;
            @media screen and (max-width: 512px) {
                flex: 1 1 auto;
                margin-bottom: 10px;
            }
            input{
                font-size: 20px;
                width: 300px;
                @media screen and (max-width: 512px) {
                    width: 100%;
                }
            }
        }

        .CC-btnSubscribeNow{
            font-weight: 400;
            height: 60px;
            @media screen and (max-width: 512px) {
                width: 100%;
            }
        }
    }
}


.CC-affiliate{
    background-color: $white;
    position: relative;
    &:after{
        content: "";
        background-image: linear-gradient(to right, rgba(0,0,0,0), #b09a51, rgba(0,0,0,0));
        height: 1px; /*for horizontal border; width for vertical*/
        display: block;
        position: absolute;
        bottom: 0;
        width: 50%;
        transform: translate(50%, 0);
    }
    
    .CC-cardBox{
        position: relative;
        border-radius: 16px;
        background-color: #f0f0f0;
        color: #000;
        padding: 60px;
        width: 100%;
        max-width: 100%;
        font-size: 20px;
        font-weight: 400;
        display: inline-block;
        margin-top: 0vh;
        height: 500px;
      }
      .CC-cardBox__header{
        font-size: 42px;
        font-weight: 500;
        color: #000;
        display: flex;
        line-height: 1;
      }
      .CC-cardBox__headerInfo{
        font-size: 24px;
        margin: 8px auto;
      }
      .CC-txtHighlight-secondary{
        color: #B09A51 !important;
        font-weight: 600;
      }
      .CC-referr-link{
        font-size: 24px;
        margin: 12px auto;
        color: $brand-secondaryColor;
      }
      .CC-referral-table {
        width: 80%;
        margin-bottom: 24px;
      }
      .CC-referral-table-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 24px;
        margin: 8px;
      }

    .CC-sectionHeading{
        margin-bottom: 30px;
        img{
            margin-right: 20px;
        }
    }
    .CC-section__contentRight{
        align-self: stretch;
    }
    .CC-buttonGroup {
        position: absolute;
        bottom: 40px;
        left: 60px;
        right: 60px;
    }
}


.CC-exchange{
    padding: 2rem 10px;
    background-color: $brand-secondaryColor;
    .CC-section__content{
        justify-content: center;
    }
}
.CC-wantToKnow{
    background-color: #3a3a3c;
    .CC-btnKnowmore{
        padding: 8px 40px;
        font-weight: 400;
    }
}

.CC-coinExchange-content{
    display: flex;
    align-items: center;
    flex-direction: column;
    @media screen and #{$CC-mediaQueryRange-desktopDevice} {
        flex-direction: row;
   }
    .CC-coinExchange-btn{
        margin: 20px 0;
        transform: rotate(90deg);
        @media screen and #{$CC-mediaQueryRange-desktopDevice} {
            margin: 0 30px;
            transform: unset;
       }
    }
}
.CC-innerPage{
    background-color: $white;
    @media screen and #{$CC-mediaQueryRange-mobileDevice} {
        padding: 20px;
    }
    .CC-section__content{
        justify-content: center;
    }
}

.m-top-20 {
    margin-top: 20px;
  }

.tierselction {
	display: flex;
	flex-direction: row;
	justify-content: center;
    flex-wrap: nowrap;
    margin-bottom: 18px;
    margin-top: 18px;
    @media screen and #{$CC-mediaQueryRange-mobileLayout} {
        // flex-direction: column;
        flex-wrap: wrap;
        margin-bottom: 0px;
        margin-top: 0px;
    }
}

.tierselction .tier {
    font-size: 14px;
    font-weight: 800;
    color: #999;
    text-transform: uppercase;
    background: none;
    cursor: pointer;
    line-height: 1;
    padding: 20px;
    border: 2px solid #b09a51;
    margin: 0px 8px 0;
    transition: .3s linear;
    width: 150px;
    @media screen and #{$CC-mediaQueryRange-mobileLayout} {
        width: 48%;
        margin: 8px 1% 0;
    }
}

.tierselction .tier span {
    font-size: 18px;
	color: #b09a51;
}

.tierselction .tier.selected {
    background: #b09a51;
    color: #eee;
}

.tierselction .tier.selected span {
	color: #000;
}

.oldPrice {
    text-decoration: none;
    font-size: 14px!important;
    position: relative;
    display: none;
}

.twobuttons {
    display: flex;
    flex-direction: row;
}

.twobuttons :first-child {
    margin-right: 10px;
}
.twobuttons :last-child {
    margin-left: 10px;
}

.oldPrice:before {
    content: " ";
    display: block;
    width: 100%;
    border-top: 2px solid rgba(128, 128, 128, 0.8);
    height: 4px;
    position: absolute;
    bottom: 8px;
    left: 0;
    transform: rotate(-11deg);
  }

.CC-exchangeS{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    @media screen and #{$CC-mediaQueryRange-desktopDevice} {
        flex-direction: row;
        padding-bottom: 0;
   }
}

.CC-exchange-content{
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    @media screen and #{$CC-mediaQueryRange-desktopDevice} {
        width: auto;
        justify-content: inherit;
   }
    .CC-exchange-btn{
        margin: 0 30px;
        // transform: rotate(90deg);
    }
}


.CC-idverificationMain{
    @media screen and #{$CC-mediaQueryRange-tabletDevice} {
        padding: 0px;
    }
    @media screen and #{$CC-mediaQueryRange-mobileDevice} {
        padding: 0px;
    }
    .CC-section__header {
        display: block;
        @media screen and #{$CC-mediaQueryRange-tabletDevice} {
            display: none;
        }
        @media screen and #{$CC-mediaQueryRange-mobileDevice} {
            display: none;
        }
    }

}