.CC-footer{
    background-color: $CC-footer__bg;
    //padding: $CC-footer__padding;
    text-align: center;
    color: $CC-footer__txtColor;
    //font-size: $CC-footer__fontSize;
    .CC-container{
        .CC-footer-container {
            padding: 50px 12px;
        }
    }
    .CC-footer-logo{
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        i{
            font-size: 30px;
            color: $CC-footer__txtColor;
        }
        .CC-fsb{
            width: 60px;
            margin: 0 10px;
        }
        .CC-iconSvg{
            height: 30px;
            display: inline-block;
            svg{
                path{
                    fill: $CC-footer__txtColor;
                }   
                height: 30px;
            }
        }
    }
    .CC-footer-content {
        display: flex;
        justify-content: space-between;
        @media screen and #{$CC-mediaQueryRange-desktopDevice} {
            //width: 60%;
        }
        @media screen and #{$CC-mediaQueryRange-mobileDevice} {
            flex-wrap: wrap;
            justify-content: space-around;
        }
        .CC-footer-linksList{        
            @media screen and #{$CC-mediaQueryRange-desktopDevice} {
                max-width: 500px;
                text-align: left;
            }
           .CC-footerLinks-title{
                font-weight: 400;
                color: $brand-secondaryColor;
                font-size: 32px;
                margin-bottom: 10px;
            }
            @media screen and #{$CC-mediaQueryRange-mobileDevice} {
                width: 100%;
                .CC-footerLinks-title{
                    margin-top: 15px;
                }
            }
            .CC-company-logo {
                margin-bottom: 10px;
                height: 120px;
            }
            .CC-social-links {
                display: flex;
                li{
                    margin: 0 10px 0 0;
                }
                @media screen and #{$CC-mediaQueryRange-mobileDevice} {
                    align-items: center;
                    justify-content: center;
                }
                img {
                    width: 32px;
                }
                
            }
            .CC-payment-methods {
                li{
                    margin-bottom: 10px;
                    img {
                        height: 32px;
                        opacity: 0.5;
                    }
                }
                @media screen and #{$CC-mediaQueryRange-mobileDevice} {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-evenly;
                }
            }
            //margin: 0 auto;
            ul{ 
                li{
                    //display: inline-block;
                    margin-bottom: 8px;
                    @media screen and #{$CC-mediaQueryRange-desktopDevice} {
                        text-align: left;
                    }
                    a{                    
                        color: $CC-footer__txtColor;
                        &:hover{
                            color: $brand-secondaryColor;
                        }
                    }
                }
            }
        }
    
    }
    
    .CC-divider{
        width: 100%;
        height: 1px;
        background-color: $CC-footer-divider__bg;
        margin: 20px 0px 30px;
        display: block;
    }
    p{
        margin: 0 0 20px 0;
    }
    .CC-footer-logoList {
        display: flex;
        justify-content: center;
        padding: 20px 0 30px;
       ul{
            display: flex;
            justify-self: center;
            align-items: center;
            li{
                padding: 0 10px;
                a{
                    img{
                        height: 20px;
                    }
                }
            }
        }
    }
    .CC-footer-copyrights {
        background-color: $brand-secondaryColor;
        color: $black;
        padding: 20px 0;
        font-size: 18px;
        font-weight: 400;
    }
    .CC-footer-copyrights-content{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        @media screen and #{$CC-mediaQueryRange-desktopDevice} {
            flex-direction: row;
        }
        ul{
            display: flex;
            margin-bottom: 20px;
            @media screen and #{$CC-mediaQueryRange-desktopDevice} {
                margin-bottom: 0;
            }
            li{
                &:not(:last-child){
                    margin-right: 50px;
                }
            }
        }
    }
}





.CC-mobile-footer{
 //   display: none;
    background-color: $CC-mobile-footer__bg;
    height: $CC-mobile-footer__height;
    position: fixed;
    bottom: 0;
    left: auto;
    right: auto;
    max-width: $CC-page__maxWidth;
    margin: 0 auto;
    border-top: 3px solid $brand-secondaryColor;
    ul{
        display: table;
        table-layout: fixed;       
        width: 100%;
        text-align: center;
        
        height: 100%;
        li{ 
            cursor: pointer;
            color: $CC-mobile-footer-item__txtColor;
            display: table-cell;             
            height: 100%;
            vertical-align: middle;
            font-size: $CC-mobile-footer-item__fontSize;
            //padding-top: 5px;
            position: relative;
            a{
                display: block;
            }
            
            i{
                font-size: $CC-mobile-footer-item-icon__fontSize;
                margin-bottom: 5px;
                display: inline-block;
                color: $CC-mobile-footer-item__txtColor;
            }
            &.active{
                color: $CC-mobile-footer-item-active__txtColor;
                i{                   
                    color: $CC-mobile-footer-item-active__txtColor;
                    font-weight: 500;
                }
            }
            .CC-iconSVG{
                height: 22px;
                margin-bottom: 3px;
                svg{
                    height: 22px;
                    path{
                        fill: $CC-mobile-footer-item__txtColor;
                    }
                }                
            }
            &.active{
                .CC-iconSVG{                   
                    svg{
                        path{
                            fill: $CC-mobile-footer-item-active__txtColor;
                        }
                    }
                }
            }
            .CC-bets-count{
                width: $CC-mobile-footer-bets-count__width;
                height: $CC-mobile-footer-bets-count__height;
                border-radius: 50%;
                background-color: $CC-mobile-footer-bets-count__bg;                
                text-align: center;
                color: $CC-mobile-footer-bets-count__txtColor;
                line-height: $CC-mobile-footer-bets-count__height + 1;
                font-weight: bold;
                display: none;
            }
            
            // &.CC-betSlip-active{
            //     background-color: $CC-mobile-footer-betslip-active__bg;
            //     color: $CC-mobile-footer-betslip-active__txtColor;
            //     font-weight: 600;
            //     .CC-bets-count{
            //         background-color: $CC-mobile-footer-betslip-active-bets-count__bg;
            //         color: $CC-mobile-footer-betslip-active-bets-count__txtColor;
            //     }
            // }
            &.CC-betslip{         
                padding-top: 0;      
                a{
                    position: relative;
                 //  margin-top: -5px;
                }           
                .CC-betslip-container{
                    box-shadow: 0px 1px 8px 0px rgba(0,0,0,0.3);
                  //  border: 3px solid $brand-secondaryColor;
                    background-color: $brand-quaternaryColor;   
                    width: 65px;
                    height: 65px;
                   // border-radius: 40px;              
                    margin-bottom: 3px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;  
                    flex-direction: column;
                    color: $white;                           
                    img{
                        width: 30px;
                    }
                    .CC-betSlip-txt{
                        position: relative;
                       // bottom: -5px;
                    }
                }
                &.CC-betSlip-active{
                    .CC-betslip-container{                     
                        img{
                            display: none;
                        }
                    }                    
                    .CC-bets-count{                        
                        display: block;
                        margin: 5px 0;
                    }
                }
                
            }
        }
        

    }
}

.CC-quickBetslip-footer{
    
    position: fixed;
    bottom: $CC-mobile-footer__height + 5px;
    left: 0;
    right: 0;
    max-width: $CC-page__maxWidth;
    margin: 0 auto;
    width: 95%;
    border-radius: 24px;
    overflow: hidden;
    @media screen and #{$CC-mediaQueryRange-desktopDevice} {
        display: none;
    }
    .CC-quickBetslip-footer-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $brand-secondaryColor;
        color: #FFF;
        padding: 0 $CC-boundaryLRSpace 2px;
        font-weight: 600;
        line-height: 1.2;
    }
    
}

.CC-bonusInfo-footer{
    // position: fixed;
    // bottom: $CC-mobile-footer__height + 20;
    // left: 0;
    // right: 0;
    // max-width: $CC-page__maxWidth;
    // margin: 0 auto;
    // width: 100%;
    background-color: $brand-secondaryColor;
   // padding-bottom: 1px;
    .CC-bonusInfo-footer-container{        
        padding: 2px 10px 0;
        font-size: 10px;        
        text-align: center;
        color: #FFF;
    }
    .CC-hr{
        display: none;
        margin: 0 12px;
        height: 1px;
        background-color: #cd567b;
    }
}