html{
    //height: 100%;
   // scroll-behavior: smooth;
  // -webkit-overflow-scrolling:touch;
  -webkit-tap-highlight-color: transparent;
    // -webkit-touch-callout: none;
}
body {
    background-color: $body-bg;
    font-family: $body-fontFamily;
    color: $body-txtColor;
    font-size: $body-fontSize;
    font-weight: $body-fontWeight;
    letter-spacing: 0.5px;
    line-height: 1.5;
    margin: 0;
    user-select: none;
    height: 100%;    
    // background-image: url('../images/betlion-bg-pattern.jpg');
    // background-attachment: fixed;
    // background-repeat: repeat;
    @media screen and #{$CC-mediaQueryRange-mobileDevice} {
        font-size: $body-fontSize - 4px;
    }
}
body{
    &.CC-sideMenu-opened{
        overflow: hidden;
        //position: fixed;
        max-width: $CC-page__maxWidth;
        margin: 0 auto;
        // .CC-Lite-Mobile{
        //     position: fixed;
        //     overflow:hidden; 
        //     width: 100%;
        // }
        .CC-leftMenu-mask{
            display: block;
        }
    }
    &.CC-rightMenu-opened{
        overflow: hidden;
       // position: fixed;
        max-width: $CC-page__maxWidth;
        margin: 0 auto;
        // .CC-Lite-Mobile{
        //     position: relative;
        //     overflow: hidden; 
        //     width: 100%;
        // }
    }
}
*,
*::before,
*::after {
    box-sizing: border-box;
}
a{
    color: inherit;
    text-decoration: none;
}
img {
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
}
h1, h2, h3, h4, h5, h6{
    padding: 0;
    margin: 0;
}
// ul, ol{
    
// }
ul {
    padding: 0;
    margin: 0;
    li {
        list-style: none;
        list-style-image: none;
        list-style-type: none;
    }
}
input,
textarea {
    font-family: inherit;
    outline: none;
}
.btn {
    cursor: pointer;
    padding: 0 6px;
    border: none;
    font-family: $body-fontFamily;
    white-space: nowrap;
    border-radius: $CC-borderRadius;
    background-color: transparent;
    // position: relative;
    border-radius: 50px;
    &:focus {
        outline: none;
        box-shadow: none;
    }
    &.btnRect{
        border-radius: 6px;
    }
}
button{
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-justify {
    text-align: justify !important;
}
.d-none {
    display: none !important;
}
.d-block {
    display: block !important;
}
.truncate {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.m-0{
    margin:0 !important;
}
.mb-0{
    margin-bottom: 0 !important;
}
.mb-10{
    margin-bottom: 10px !important;
}
.mb-20{
    margin-bottom: 20px !important;
}
.CC-space5 {
    height: 5px;
}

.CC-space10 {
    height: 10px;
}
.CC-space15 {
    height: 15px;
}
.CC-space20 {
    height: 20px;
}

table {
    width: 100%;
    max-width: 100%;    
    background-color: transparent;
    border-collapse: collapse !important;
}


