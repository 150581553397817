
.CC-form-group{
	.CC-form-label{
		font-size: 12px;
		color: $CC-form-label__txtColor;
		margin-bottom: 3px;
	}	
    // .CC-helpTxt{
    //     font-size: 11px;
    //     margin-top: 3px;
    //     color: #777;
    // }
}
.CC-form-errorMsg{
    color: $CC-form-errorMsg__txtColor;
    min-height: $CC-form-errorMsg__minHeight;
}
.CC-form-successMsg{
    color: $CC-form-successMsg__txtColor;
    min-height: $CC-form-errorMsg__minHeight;
}
.CC-form-control{
    input{
        width: 100%;
        padding: 0 20px;
        height: $CC-form-control__height;
        border-radius: $CC-form-control__borderRadius;
        font-size: $CC-form-control__fontSize;
        color: $CC-form-control__color;
        border: $CC-form-control__borderColor;
        background-color: $CC-form-control__bg;
        font-weight: 500;
        padding-bottom: 2px;
        
        @media screen and #{$CC-mediaQueryRange-mobileDevice} {
            &::-webkit-input-placeholder{
                font-size: 18px;
                font-weight: 300;
            }
            &::-moz-placeholder {
                font-size: 18px;
                font-weight: 300;
            } 
            &:-ms-input-placeholder {
                font-size: 18px;
                font-weight: 300;
            }
        }
    }		
    &.CC-error{
        input{
            border: $CC-form-control__errorBorderColor;
            background-color: #FFECEC;
            color: $CC-errorColor;
        }       
    }
    // &.CC-validated{
    //     position: relative;
    //     input{
    //         color: $CC-successColor;
    //         border: 1px solid $CC-successColor;
    //     }
    //     &::after{
    //         content: url('#{$images-path}/other-icons/input-check.svg');
    //         position: absolute;
    //         right: 5px;
    //         top: 50%;
    //         transform: translateY(-50%);
    //         height: 16px;
    //     }
    // }
    &.CC-form-control-pin{
        input[type=tel] {
            -webkit-text-security: disc;
        }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;           
    }
    input[type=number] {
        -moz-appearance: textfield;
    }
    ::-webkit-input-placeholder { /* Edge */
        color: #777;
        opacity: 0.5;
      }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #777;
        opacity: 0.5;
      }
      
      ::placeholder {
        color: #777;
        opacity: 0.5;
      }
}

.CC-form-control-group{
    display: flex;   
    border: $CC-form-control-group__borderColor;
    border-radius: $CC-form-control__borderRadius;
    overflow: hidden;
    .CC-form-addon{
        min-width: 70px;
        max-width: 70px;
        height: 100%;
        text-align: center;
        background-color: $white;
        height: $CC-form-control-form-addon__height;
        display: flex;
        align-items: center;
        justify-content: center;
       // border-radius: #{$CC-form-control__borderRadius} 0 0 #{$CC-form-control__borderRadius};
        color:$CC-form-control-form-addon__txtColor;
        font-weight: 400;
        padding: 0 5px;
        cursor: pointer;
        border-right: 1px solid $brand-secondaryColor;
        font-size: 20px;
        line-height: 24px;
        // &.CC-addon-left{
        //     background-color: $CC-form-control-form-addon__bg;
        //     min-width: 70px;
        //     max-width: 70px;
        // }
        // &.CC-addon-right{
        //     background-color: #104118;
        //     justify-content: center;
        //     cursor: pointer;
        //     img{
        //         width: 20px;
        //         min-width: 20px;
        //         max-width: 20px;
        //     }
        // }
    }
    .CC-form-control{
        flex: 1 1 auto;
        input{
            border-radius: 0 #{$CC-form-control__borderRadius} #{$CC-form-control__borderRadius} 0;
            border: 0;
        }
    }
    &.CC-error{       
       border: $CC-form-control__errorBorderColor;
    //    input{
    //        background-color: #FFECEC;
    //    }
        border-radius: #{$CC-form-control__borderRadius};        
    }
    &.CC-success{
        border: 1px solid $CC-successColor;
        .CC-form-addon{
            background-color: #FFF;
            justify-content: center;
            cursor: pointer;
            img{
                width: 20px;
                min-width: 20px;
                max-width: 20px;
            }
        }
    }
}
.OTP-form-Group{
    .CC-form-control-group{
        border: 0;
        justify-content: center;
        input{
            max-width: 64px !important;
            text-align: center;
            padding: 0;
            &:not(:last-child){
                margin-right: 20px;
                @media screen and #{$CC-mediaQueryRange-mobileDevice} {
                    margin-right: 6px;
                }
            }
        }
    }
}
.CC-cardBox{
    .CC-inputBtnGroup{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .CC-form-control{
            flex: 1 1 auto;
            margin-right: 20px;
            @media screen and #{$CC-mediaQueryRange-mobileDevice} {
                margin-right: 0;
                margin-bottom: 10px;
            }
        }
        .CC-btnCardBox{
            width: 170px;
            border-radius: 6px;
            height: 60px;
            font-size: 24px;
            @media screen and #{$CC-mediaQueryRange-mobileDevice} {
                width: 100%;
            }
        }
    }
}


// ==================================================
//  Check box CSS
// ==================================================
.CC-checkbox {
	display: flex;
	align-items: center;
    .CC-checkboxLabel {
        padding-left: 10px;
        //padding-right: 16px; // white-space: nowrap;
        // width: calc(100% - 22px);
        position: relative;
       // top: -3px;
        color: $black;
        font-size: 20px;
        font-weight: 400;
    }
    input[type=radio],
    input[type=checkbox] {
        display: none;
    }
    label {
        width: $CC-checkbox__width;
        height: $CC-checkbox__height;
        min-width: $CC-checkbox__width;
        margin-bottom: 0;
        padding-left: 0;
        padding-bottom: 0;
        float: left;
        position: relative;
        &::before {
            content: "";
            display: inline-block;
            width: $CC-checkbox__width;
            height: $CC-checkbox__height;
            position: absolute;
            left: 0;            
            background-color: $CC-checkbox__bg;
			border: $CC-checkbox__borderColor;
			border-radius: $CC-checkbox__borderRadius;
        }
    }
    input[type=checkbox]:checked+label:before {			       
        text-align: center;
        line-height: $CC-checkbox__lineHeight + 8px;
        color: $CC-checkbox__color;
        font-family: '10bet-icon' !important;
        content: url('#{$images-path}/other-icons/checkbox-check.svg');        
        border: 0;
        background-color: $CC-checkbox-checked__bg;
       
    }

    a{
        text-decoration: underline;
    }
}
