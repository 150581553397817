// ==================================================
// Content Layout CSS 
// ==================================================
// .CC-Lite-Mobile{
//     max-width: $CC-page__maxWidth;
//     margin: 0 auto;
//     overflow-x: hidden;
//     position: relative;
//     height: 100%;
//     background-color: #151921;
//     display: block;
// }

body {
    &.CC-sticky-layoutHeader{
        .CC-mainHeader{
            position: fixed;
            top: 0;
            max-width: $CC-page__maxWidth;
            z-index: 116;
            width: 100%;
        }
        .CC-mainHeader{
            height: auto;
        }
        .CC-layout-content {
            margin-top: 114px;
        }
    }
}

.CC-container{
    max-width: 1240px;
    margin: 0 auto;
    @media screen and (min-width: 1024px){
        padding: 0 40px;
    }
}