
 @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600&display=swap');
 @import url('https://fonts.googleapis.com/icon?family=Material+Icons')
 
// @font-face {
//   font-family: "Montserrat";
//   src: url("#{$fonts-path}/Gilroy-Light.otf");
//   src: local("☺"),
//   url("#{$fonts-path}/Gilroy-Light.otf") format("woff");
//   font-weight: 300;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Montserrat";
//   src: url("#{$fonts-path}/Gilroy-Regular.otf");
//   src: local("☺"),
//   url("#{$fonts-path}/Gilroy-Regular.otf") format("woff");
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Montserrat";
//   src: url("#{$fonts-path}/Gilroy-Medium.otf");
//   src: local("☺"),
//   url("#{$fonts-path}/Gilroy-Medium.otf") format("woff");
//   font-weight: 600;
//   font-style: normal;
// }
// @font-face {
//   font-family: "Montserrat";
//   src: url("#{$fonts-path}/Gilroy-Bold.otf");
//   src: local("☺"),
//   url("#{$fonts-path}/Gilroy-Bold.otf") format("woff");
//   font-weight: bold;
//   font-style: normal;
// }