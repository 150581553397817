b {
    font-weight: bold;
}
p {
    margin: 0 0 10px 0;
}
.CC-content {
    padding-left: 12px;
    padding-right: 12px;
}
.CC-subTitle{
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 5px;
}
.CC-txtHighlight-primary{
    color: $brand-primaryColor !important;
    font-weight: 600;
}
.CC-txtHighlight-secondary {
    color: $brand-secondaryColor !important;
    font-weight: 600;
}
.CC-txtHighlight-tertiary {
    color: $brand-tertiaryColor !important;
    font-weight: 600;
}
.CC-textLower{
    text-transform: none !important;
}
.CC-tabs{
    overflow-x: auto;
    margin: 0 -10px;
    @media screen and #{$CC-mediaQueryRange-desktopDevice} {
        margin: 0 12px;
        overflow-x: unset;
    }
    ul{
        display: flex;
        align-items: center;
        //margin-top: 5px;
        border-bottom: 1px solid $brand-secondaryColor;
       li{
            @media screen and #{$CC-mediaQueryRange-mobileLayout} {
                flex: 1 1 0%;
            }
            .CC-tab-item{
                font-size: 30px;
                font-weight: 300;
                color: #747984;
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 42px;
                cursor: pointer;
                text-align: center;
                line-height: 1.2;
                padding: 18px 40px;
                position: relative;
                //border-bottom: 1px solid #747984;
                @media screen and #{$CC-mediaQueryRange-mobileLayout} {
                    font-size: 28px;
                    padding: 14px 30px;
                }
                &.active, &:hover{
                    color: $brand-secondaryColor;
                    font-weight: 600;
                    //border-bottom: 2px solid $brand-secondaryColor;
                    //margin-bottom: -1px;
                    @media screen and #{$CC-mediaQueryRange-mobileLayout} {
                        border-radius: 0;
                    }
                }
                &.active{
                     &:hover{
                        font-weight: 600;
                     }
                }
                &:hover{
                    font-weight: 300;
                }
            }
        }
    }
}

.CC-tabs-pills {
    padding-bottom: 30px;
    overflow-x: auto;
    ul {
        display: flex;
        flex-wrap: wrap;
        li {
            .CC-tab-item{
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #ccc;
                border-radius: 30px;
                background-color: transparent;
                padding: 8px 18px;
                color: #eaeaea;
                font-size: 16px;
                text-align: center;
                cursor: pointer;
                min-width: 80px;
                height: 42px;
                &.active {
                    background-color: $brand-secondaryColor;
                    color: $white;
                    border-color: $brand-secondaryColor;
                    &:hover{
                        background-color: $brand-secondaryColor;
                        color: $white;
                        border-color: $brand-secondaryColor;
                    }
                }
                &:hover{
                    color: $brand-secondaryColor;
                    border-color: $brand-secondaryColor;
                }
            }
            &:not(:last-child){
                margin-right: 8px;
                margin-bottom: 8px;
                @media screen and #{$CC-mediaQueryRange-desktopDevice} {
                    margin-right: 15px;
                }
            }
        }
    }
}

.CC-tab-content{
    .CC-tab-pane{
        display: none;
        &.active{
            display: block;
        }
    }
}

.CC-filters{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px $CC-boundaryLRSpace;
   // margin-bottom: 8px;
    overflow: auto;

    .CC-filters-left{
        .CC-filterItem{
            min-width: 70px;
            justify-content: center;
        }
    }
    .CC-filters-right{
        display: flex;
    }
    .CC-filterItem{
        background-color: $brand-secondaryColor;
        border: 1px solid #999;
        color: #000;
        border-radius: 16px;
        padding: 4px 6px;
        display: flex;
        align-items: center;
        height: 28px;
        font-weight: 600;
        white-space: nowrap;
        img{
            width: 14px;
            margin-right: 5px;
        }
    }
    .CC-select{
       // flex: 1 1 0%;
        margin-left: 5px;
        background-color: #FFF;
        border: $CC-filterButtons-item__borderColor;
        color: #000;
        border-radius: 16px;
        height: 28px;
        width: auto;
        display: inline-block;
        select{
            width: auto;
        }
    }
}

/*----------- CardBox CSS -----------*/

.CC-cardBox{
    border-radius: 16px;
    background-color: #f0f0f0;
    color: $black;
    padding: 60px;
    width: 100%;
    max-width: 750px;
    font-size: 20px;
    font-weight: 400;
    position: relative;
    @media screen and #{$CC-mediaQueryRange-mobileDevice} {
        padding: 20px;
    }
    .CC-cardBox__header{
        font-size: 42px;
        font-weight: 500;
        color: $black;
        display: flex;
        line-height: 1;
        @media screen and #{$CC-mediaQueryRange-mobileDevice} {
            font-size: 36px;
        }
        .CC-cardBoxClose{
            width: 48px;
            height: 48px;
            img{
                cursor: pointer;
                position:absolute;
                right: 20px;
                top: 20px;
                height: 48px;
            }
        }
    }
    .CC-cardBox__headerInfo{
        font-size: 24px;
        margin-top: 8px;
        @media screen and #{$CC-mediaQueryRange-mobileDevice} {
            font-size: 20px;
        }
    }
    .CC-cardBox__body{
        padding: 20px 0;
        text-align: center;
        .CC-cardBox__bodyDesc{
            color: #999;
            padding-bottom: 30px;
        }
    }
    .CC-form-group{
        margin-bottom: 45px;
        @media screen and #{$CC-mediaQueryRange-mobileDevice} {
            margin-bottom: 20px;
        }
        input{
            width: 100%;
        }
    }
    .CC-cardBox__footer{
        text-align: center;
        color: #999;
        a{
            text-decoration: underline;
            color: $brand-secondaryColor;
        }
    }
    .CC-btnCardBox{
        width: 100%;
        font-size: 32px;
        font-weight: 500;
        height: 70px;
        border-radius: 50px;
        @media screen and #{$CC-mediaQueryRange-mobileDevice} {
            height: 60px;
            font-size: 28px;
        }
    }
    .CC-form-control input{
        height: 50px;
        font-size: 20px;
    }
}
.CC-rowelem-left {
    margin-right: 25px;
    @media screen and #{$CC-mediaQueryRange-tabletDevice} {
        margin-right: 0px;
    }
    @media screen and #{$CC-mediaQueryRange-mobileDevice} {
        margin-right: 0px;
    }
}
.CC-rowelem-right {
    margin-left: 25px;
    @media screen and #{$CC-mediaQueryRange-tabletDevice} {
        margin-left: 0px;
        margin-top: 25px;
    }
    @media screen and #{$CC-mediaQueryRange-mobileDevice} {
        margin-left: 0px;
        margin-top: 25px;
    }
}
.CC-section__content{
    &.CC-IDverification{
        flex-direction: column;
        .CC-cardBox{
            min-width: 100%;
            text-align: center;
            padding: 0px;
            height: 80vh;
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and #{$CC-mediaQueryRange-tabletDevice} {
                height: 90vh;
            }
            @media screen and #{$CC-mediaQueryRange-mobileDevice} {
                height: 90vh;
            }
        }
        .CC-cardBox__footer{
            padding: 30px;
            button{
                width: 200px;
                font-size: 32px;
                font-weight: 500;
            }
        }
    }
    &.CC-processing{
        .CC-cardBox__bodyDesc{
            background-color: $white;
            border-radius: 6px;
            padding: 30px;
        }
        .CC-barcode{
            img{
                height: 143px;
            }
        }
    }
    &.CC-success{
        .CC-cardBox__bodyDesc{
            color: #333;
            .CC-txtHighlight-secondary{
                font-weight: 500;
                font-size: 36px;
            }
        }
        .CC-successImg{
            img{
                height: 220px;
            }
        }
    }
}
.stretch {
    align-items: stretch!important;
}

/*----------- Slider CSS -----------*/

.CC-slideContainer {
    width: 100%;
    text-align: center;
    .CC-slider {
        -webkit-appearance: none;
        width: 100%;
        height: 2px;
        background: #666;
        outline: none;
        -webkit-transition: .2s;
        transition: opacity .2s;
    }
    .CC-slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background: $brand-secondaryColor;
        cursor: pointer
    }
    .CC-slider::-moz-range-thumb {
        width: 45px;
        height: 45px;
        background: $brand-secondaryColor;
        cursor: pointer
    }
}


.CC-accordion {
    >.CC-accordion-header {
        justify-content: space-between;
        &.CC-arrowBefore {
            position: relative;
            &:before {
                
              font-family: '10bet-icon' !important;	
              content: "\e951";            
              color: $white;
              font-size: 10px;
            }
            
        }
        &.CC-arrowAfter {
            position: relative;
            &:after {                
                font-family: '10bet-icon' !important;	
                content: "\e951";
                color: inherit;
                font-size: 10px;
            }            
        }
        .CC-arrowAfter {
            position: relative;
            &:after {                
                font-family: '10bet-icon' !important;	
                content: "\e951";
                color: inherit;
                font-size: 10px;
            }            
        }
    }
    &.collapsed {        
        >.CC-accordion-header {
            &.CC-arrowBefore {
                &:before {
                    content: "\e94e";
                }
            }
            &.CC-arrowAfter {
                &:after {
                    content: "\e94e";                   
                }
            } 
            .CC-arrowAfter {
                &:after {
                    content: "\e94e";                   
                }
            }            
        }
        >.CC-accordion-content {           
            display: none;
        }
    } 
    .CC-accordionArrow{
        position: relative;
        &:after {                
            font-family: '10bet-icon' !important;	
            content: "\e951";
            color: inherit;
            font-size: 10px;
        }
    }
    &.collapsed {
        .CC-accordionArrow{
            &:after {
                content: "\e94e";                   
            }
        }
    }
}


.CC-showMore {
    text-align: center;
    color: $CC-showMore__txtColor;
    font-size: $CC-showMore__fontSize;
    padding: 10px 0;
    margin-bottom: 10px;
    cursor: pointer;
    span {
        text-decoration: underline;
    }
    i {
        font-size: $CC-showMore__arrow-fontSize;
    }
}
.CC-btnShowAll {
    width: auto;
    min-width: 120px;
    margin: 30px auto;
    font-size: 14px;
    .CC-icon {
        margin-left: 6px;
        font-size: 12px;
    }
}

/*----------- Side menu CSS -----------*/

.CC-myAccount-beforeLogin-container {
    text-align: center;
    padding: 15px 10px;
    .CC-btnLogin {
        width: 150px;
        margin-top: 15px;
    }
}

.CC-myAccount-afterLogin-container {
    .CC-logout-container {
        padding: 20px 0;
        text-align: center;
        a{
            display: inline-block;
        }
        .CC-btnLogout {
            width: 150px;
            @media screen and #{$CC-mediaQueryRange-tabletDevice} {
                width: 90px;
            }
            @media screen and #{$CC-mediaQueryRange-mobileDevice} {
                width: 90px;
            }
        }
    }
}

.CC-contentBlock {
    display: flex;
    justify-content: space-between;
    .CC-leftContent {
        // flex: 1 1 auto;
        text-align: left;
        color: $white;
       // max-width: 45%;
    }
    .CC-rightContent {
        // flex: 1 1 auto;
        text-align: right;
        color: $white;
      //  max-width: 55%;
    }
}

/*------------- Registration Page CSS -----------*/


/*-------------- Page specific CSS --------------*/



.CC-pageHeaderTabs{
   // background-color: #000;
   // padding: 0 10px;
    min-height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and #{$CC-mediaQueryRange-mobileDevice} {
        display: block;
        .CC-tabsDeafult{
            ul{
                li{
                    flex: 1 1 auto;
                    margin-right: 0;
                    white-space: nowrap;
                }
            }
        }
        .CC-marketDropDown-container{
            padding: 8px 0;
        }
    }
    &.CC-center{
        justify-content: center;
        @media screen and #{$CC-mediaQueryRange-mobileDevice} {
            justify-content: flex-start;
        }
    }
}


.CC-scrollableArea {
    &.CC-horizontal {
        overflow-x: auto;
    }
}

iframe{
    border: 0;
    width: 100%;       
}

.CC-hideOnMobile{    
    @media screen and #{$CC-mediaQueryRange-mobileDevice} {
        display: none !important;
    }
}

.CC-scrolltoTop{
    position: fixed;
    bottom: 52px;
    right: 10px;
    z-index: 101;
    background-color: $CC-scrolltoTop__bg;
    padding: 2px;
    border-radius: 4px;
    opacity: 0.8;
    cursor: pointer;
    display: none;
    .CC-iconSvg{
        display: flex;
        svg{
            path{
                fill: $CC-scrolltoTop__svgFill;
            }
        }
    }    
    @media screen and #{$CC-mediaQueryRange-tabletDevice} {
        right: calc(50% - 373px);
    }
}

.CC-hide-mobile{
    display: none;
}
.CC-show-mobile{
    display: block;
}
@media screen and #{$CC-mediaQueryRange-desktopDevice} {
    .CC-show-desktop{
        display: block;
    }
    .CC-hide-desktop{
        display: none;
    }
}

.CC-dropdown{
    position: relative;
    .CC-dropdown-menu {
        display: none;
        position: absolute; 
        z-index: 999;
       // overflow: hidden;
        >ul{
            >li{
                padding: 10px 10px;
                border-bottom: 1px solid #EEE;
                cursor: pointer;
                &:last-child{
                    border-bottom: 0;
                }                
            }
        }
    }
    &.active{
        .CC-dropdown-menu {
            display: block;
        }
    }
}

.ccbtnspinnerHolder {
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.ccbtnspinner {
    display: inline-block;
    width: 70px;
    height: 70px;
  }
  .ccbtnspinner:after {
    content: " ";
    display: block;
    width: 40px;
    height: 40px;
    margin: 15px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: ccbtnspinner 1.2s linear infinite;
  }
  @keyframes ccbtnspinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .numbercoins {
    margin: 20px 0;
    font-size: 36px;
  }

  .margin-t-20 {
    margin-top: 20px
  }

  .margin-left-20 {
    margin-left: 20px
  }

  .walletaddress {
    font-size: 20px;
    @media screen and #{$CC-mediaQueryRange-mobileDevice} {
        font-size: 12px;
    }
  }

  .exchangeLogo {
    height: 20px;
  }