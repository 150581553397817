$fonts-path: "../fonts";
$images-path: "../../images";

$brand-primaryColor: #151921;
$brand-secondaryColor: #B09A51;
$brand-tertiaryColor: #051607;
$brand-quaternaryColor: #388a49;


$CC-mobileLayout-minWidth: 300px;
$CC-mobileLayout-maxWidth: 1023px;
$CC-mediaQueryRange-mobileLayout: "(min-width: #{$CC-mobileLayout-minWidth}) and (max-width: #{$CC-mobileLayout-maxWidth})";
@media screen and #{$CC-mediaQueryRange-mobileLayout} {
   
}

$CC-specialDevice-width: 300px;
$CC-mediaQueryRange-specialDevice: "(max-width: #{$CC-specialDevice-width})";
@media screen and #{$CC-mediaQueryRange-specialDevice} {
   
}

$CC-smallMobileDevice-minWidth: 320px;
$CC-smallMobileDevice-maxWidth: 375px;
$CC-mediaQueryRange-smallMobileDevice: "(min-width: #{$CC-smallMobileDevice-minWidth}) and (max-width: #{$CC-smallMobileDevice-maxWidth})";
@media screen and #{$CC-mediaQueryRange-smallMobileDevice} {
   
}

$CC-mobileDevice-minWidth: 200px;
$CC-mobileDevice-maxWidth: 767px;
$CC-mediaQueryRange-mobileDevice: "(min-width: #{$CC-mobileDevice-minWidth}) and (max-width: #{$CC-mobileDevice-maxWidth})";
@media screen and #{$CC-mediaQueryRange-mobileDevice} {
   
}

$CC-tabletDevice-minWidth: 768px;
$CC-tabletDevice-maxWidth: 1024px;
$CC-mediaQueryRange-tabletDevice: "(min-width: #{$CC-tabletDevice-minWidth}) and (max-width: #{$CC-tabletDevice-maxWidth})";
@media screen and #{$CC-mediaQueryRange-tabletDevice} {
   
}

$CC-desktopDevice-minWidth: 1024px;
$CC-mediaQueryRange-desktopDevice: "(min-width: #{$CC-desktopDevice-minWidth})";
@media screen and #{$CC-mediaQueryRange-desktopDevice} {
   
}

/*------- Global Variables ----------------*/
$white: #FFF;
$black: #000;
$footer-bg-color: #081409;

$body-bg: #090909;
$body-txtColor: $white;
$body-fontSize: 24px;
$body-fontWeight: 300;
$body-fontFamily: 'Oswald', sans-serif;
$CC-btn-fontFamily: 'Oswald', sans-serif;
$headers-fontFamily: 'Bebas Neue', cursive;

$CC-page__maxWidth: 100%;

$CC-borderColor: #999;
$CC-borderRadius: 4px;
$CC-boundaryLRSpace: 12px;
// $CC-boundaryLRSpace-special: 8px;

$CC-successColor: #27AE60;
$CC-errorColor: #ff3a3a;


/*----------------------- Buttons Variables ------------------*/

$CC-btnExtraLarge__height: 60px;
$CC-btnExtraLarge__fontSize: 24px;

$CC-btnLarge__height: 50px;
$CC-btnLarge__fontSize: 22px;

$CC-btnMedium__height: 32px;
$CC-btnMedium__fontSize: 16px;

$CC-btnSmall__height: 28px;
$CC-btnSmall__fontSize: 14px;

$CC-btnDefault__borderColor: 1px solid #999;
$CC-btnDefault__txtColor: #333;
$CC-btnDefault-active__bg: #DDD;
$CC-btnDefault-active__txtColor: #000;

$CC-btnPrimary__borderColor:1px solid $brand-primaryColor;
$CC-btnPrimary__textColor: $brand-primaryColor;
$CC-btnPrimary__active-bg: $brand-primaryColor;
$CC-btnPrimary__active-txtColor: #FFF;

$CC-btnSecondary__borderColor: 1px solid $brand-primaryColor;
$CC-btnSecondary__textColor: $brand-secondaryColor;
$CC-btnSecondary__active-bg: $brand-secondaryColor;
$CC-btnSecondary__active-txtColor: $black;

$CC-btnTertiary__borderColor: 1px solid $brand-tertiaryColor;
$CC-btnTertiary__textColor: $brand-tertiaryColor;
$CC-btnTertiary__active-bg: $brand-tertiaryColor;
$CC-btnTertiary__active-txtColor: #FFF;

$CC-btnQuaternary__borderColor: 1px solid $brand-quaternaryColor;
$CC-btnQuaternary__textColor: $brand-quaternaryColor;
$CC-btnQuaternary__active-bg: $brand-quaternaryColor;
$CC-btnQuaternary__active-txtColor: #FFF;


/*----------------------- Odds Buttons Variables ------------------*/
$CC-btnOdds__width: 60px;
$CC-btnOdds__height: 100%;
$CC-btnOdds__bg: #EEE;
$CC-btnOdds__borderColor: 1px solid #EEE;
$CC-btnOdds__borderRadius: 3px;
$CC-btnOdds__txtColor: #165521;
$CC-btnOdds__fontSize: 12px;
$CC-btnOdds__fontWeight: 500;
$CC-btnOdds__padding: 0 4px;
$CC-btnOdds-highlight__borderColor: 1px solid $brand-tertiaryColor;
$CC-btnOdds-marketOutcome__txtColor: #666;
$CC-btnOdds-active__bg: $brand-secondaryColor;
$CC-btnOdds-active__txtColor: $black;
$CC-btnOdds-active__fontWeight: 600;
$CC-btnOdds-active-marketOutcome__txtColor: #000;
$CC-btnOutComeOdds__txtColor: #222;
$CC-btnOutComeOdds-active__txtColor: $black;
$CC-btnOutComeOdds-odds-FP__txtColor: $brand-primaryColor;
$CC-btnOutComeOdds-odds-FP-active__txtColor: $black;


$CC-btnOddsDisabled-bg: #FFF;
$CC-btnOddsDisabled-borderColor: 1px solid #CCC;
$CC-btnOddsDisabled-txtColor: #848484;
$CC-btnOddsDisabled-opacity: 0.6;
$CC-btnOddsSuspended-bg: $CC-btnOddsDisabled-bg;
$CC-btnOddsSuspended-borderColor: #FF0000;
$CC-btnOddsSuspended-lockIcon-color: #333;

$CC-btnOddsDecreased__arrowColor: #FF0000;
$CC-btnOddsIncreased__arrowColor: $brand-secondaryColor;

$CC-btnDisabled-bg: #AAA;    /*---- Newly added variables ----*/
$CC-btnDisabled-borderColor: 1px solid $CC-btnDisabled-bg;
$CC-btnDisabled-txtColor: #000;
$CC-btnDisabled-opacity: 0.5;

$CC-btnJackpotScore__fontSize: 20px;
$CC-btnJackpotScore-won__bg: $brand-secondaryColor;
$CC-btnJackpotScore-won__txtColor: #000;
$CC-btnJackpotScore-draw__bg: #888888;

$CC-btnOdds-won__bg: $brand-secondaryColor;
$CC-btnOdds-won__txtColor: #000;
$CC-btnOdds-lost__bg: $CC-errorColor;
$CC-btnOdds-lost__txtColor: #FFF;

/*----------------------- Form Elementes Variables ------------------*/
/*----- Dropdowns -----*/
// $CC-select__bg: #0a2b0e;
// $CC-select__borderRadius: 3px;
// $CC-select__height: 32px;
// $CC-select__txtColor: #FFF;
// $CC-select__fontSize: 11px;
// $CC-select__fontWeight: 500;
// $CC-select__arrow: "\e913";
// $CC-select__borderColor: 1px solid $CC-borderColor;
// $CC-select-option__txtColor: #FFF;
// $CC-select-arrow__color: $brand-primaryColor;

/*----- Input ------*/
$CC-form-label__fontSize: 14px;
$CC-form-label__txtColor: $white;
$CC-form-group-label__txtColor: #444;

$CC-form-errorMsg__txtColor: $CC-errorColor;
$CC-form-errorMsg__minHeight: 20px;
$CC-form-successMsg__txtColor: $CC-successColor;

$CC-form-control__borderColor: 1px solid $CC-borderColor;
$CC-form-control-group__borderColor: 1px solid $CC-borderColor;
$CC-form-control__bg: $white;
$CC-form-control__height: 60px;
$CC-form-control__borderRadius: 6px;
$CC-form-control__fontSize: 24px;
$CC-form-control__color: $black;
$CC-form-control__errorBorderColor: 1px solid $CC-errorColor;

$CC-form-control-form-addon__bg: #104118;
$CC-form-control-form-addon__height: $CC-form-control__height;
$CC-form-control-form-addon__txtColor: #999;

$CC-checkbox__width: 30px;
$CC-checkbox__height: 30px;
$CC-checkbox__lineHeight: 24px;
$CC-checkbox__bg: transparent;
$CC-checkbox__borderColor: 1px solid #666;
$CC-checkbox-checked__bg: $brand-secondaryColor;
$CC-checkbox__borderRadius: 4px;
$CC-checkbox__fontSize: 11px;
$CC-checkbox__color: #222;

$CC-radioBox__bg: #FFF;
$CC-radioBox__border: 1px solid #CCC;
$CC-radioBox-checked__bg: $brand-primaryColor;
$CC-radioBox-checked__txtColor: $brand-primaryColor;
$CC-radioBox-checked__borderColor: 1px solid $brand-primaryColor;

/*----------------------- Common Variables -------------------------*/
$CC-tabs__bg: #EFEFEF;
$CC-tabs__borderBottomColor: 1px solid #d9e1ff;
$CC-tabs__txtColor: $brand-primaryColor;
$CC-tabs__fontSize: 14px;
$CC-tabs__active-txtColor: $brand-primaryColor;
$CC-tabs__active-borderBottomColor: 2px solid $brand-secondaryColor;
$CC-tabs__highlight-txtColor: $brand-secondaryColor;
$CC-tabs-searchButton-iconSvg__color: $brand-primaryColor;

$CC-tabs-boxed__bg: #131c5d;
$CC-tabs-boxed__border:1px solid #3d4a8c;
$CC-tabs-boxed-active__bg: $brand-tertiaryColor;
$CC-tabs-boxed-active__txtColor: #000;
$CC-viewmoreButton__color: $brand-primaryColor;


$CC-filterButtons__bg: #efefef;
$CC-filterButtons__height: 60px;
$CC-filterButtons-item__bg: #0e3515;
$CC-filterButtons-item__borderColor: 1px solid #444;
$CC-filterButtons-item__height: 32px;
$CC-filterButtons-item__min-width: 80px;
$CC-filterButtons-item__fontSize: 12px;
$CC-filterButtons-item__lineHeight: 14px;
$CC-filterButtons-item__txtColor: $white;
$CC-filterButtons-item-active__bg: $brand-secondaryColor;
$CC-filterButtons-item-active__txtColor: #000;

$CC-subFilterButtons__txtColor: #000;
$CC-subFilterButtons-active__borderBottomColor: 3px solid $brand-secondaryColor;
$CC-subFilterButtons-active__txtColor: #000;


$CC-upcoming-filter-tabs__item-bg: transparent;
$CC-upcoming-filter-tabs__item-txtColor: #999;
$CC-upcoming-filter-tabs__item-height: 32px;
$CC-upcoming-filter-tabs__item-min-width: 30px;
$CC-upcoming-filter-tabs__item-borderColor: 1px solid $CC-borderColor;
$CC-upcoming-filter-tabs__item-active-borderColor: 1px solid $CC-borderColor;
$CC-upcoming-filter-tabs__item-active-bg: #333;
$CC-upcoming-filter-tabs__item-active-txtColor: #FFF;

$CC-leagueBox-header__bg: #293629;
$CC-leagueBox-header__height: 32px;
$CC-leagueBox-header__txtColor: $white;
$CC-leagueBox-header__fontSize: 12px;
//$CC-leagueBox-content__borderColor: 0;
$CC-leagueBox-header-leagueName__txtColor: $brand-secondaryColor;

$CC-matchBox__txtColor: $white;
$CC-matchBox__borderBottomColor: 1px solid #165521;
$CC-matchBox-leagueName__txtColor: #848484;
$CC-matchBox__fixtureInfo__fontSize: 12px;
$CC-matchBox__extraInfo__fontSize: 10px;
$CC-matchBox__extraInfo__txtColor: #AAA;
$CC-match__marketsCount__height: 13px;
$CC-match__marketsCount__fontSize: 10px;
$CC-match__marketsCount__txtColor: #ccc;
$CC-match__marketsCount___bg: #303030;
$CC-match__matchMinute__txtColor: $brand-quaternaryColor;

$CC-collapsed-accordion-header-arrowBefore__color: #FFF;

$CC-accordion-header-arrowBefore__color: #FFF;
$CC-accordion-collapsed-borderColor: 1px solid #3d4a8c;
$CC-league-accordion-header-arrowBefore__color: #FFF;
$CC-league-accordion-collapsed-borderColor: 1px solid #303878;
$CC-categoryList-header-accordion-arrow__color: $brand-primaryColor;
$CC-categoryList-header-accordion-collapsed__borderBottom: 1px solid #b9c4ee;
$CC-categoryListItem-accordion-arrow__color: $brand-primaryColor;
$CC-categoryListItem-accordion-content__color: #222;
$CC-categoryListItem-accordion-content__bg:#f5f7ff;
$CC-totalStakeOddsWins-accordion-arrow__color: $brand-primaryColor;
$CC-betBox-accordion-arrow__color: $brand-primaryColor;
$CC-betBox-header-accordion-collapsed__borderBottom: 1px solid #b9c4ee;

$CC-match__score__width: 15px;
$CC-match__score__txtColor: #666;
$CC-match__score__active-txtColor: $brand-secondaryColor;

$CC-tennis-gameScore__borderRight: 1px solid #555;
$CC-tennis-gameScore__width: 20px;
$CC-tennis-setPoints__txtColor: #444;

$CC-cricket-score__width: 35px;

$CC-matchBox-vs__txtColor: $brand-primaryColor;

$CC-servingBefore__size: 5px;
$CC-servingBefore__bg: $brand-primaryColor;

$CC-showMore__txtColor: $brand-primaryColor;
$CC-showMore__fontSize: 14px;
$CC-showMore__arrow-fontSize: 10px;

$CC-freeBet-banner__bg: $brand-primaryColor;
$CC-freeBet-banner__txtColor: #FFF;

$CC-outRights-leagueHeader__txtColor: $brand-tertiaryColor;
$CC-outRights-marketBox-header__bg: #1f232b;
$CC-outRights-marketBox-content__borderColor: $CC-borderColor;

/*--------------- Banner Variables ----------*/
$CC-countDown__bg: $white;
$CC-countDown__txtColor: $brand-primaryColor;
$CC-banner-pickSix__bg: #222;
$CC-banner-pickSix__txtColor: $white;
$CC-banner-pickSix-prizeMoney__txtColor: $brand-tertiaryColor;
$CC-banner-pickSix-billionTxt__color: $brand-tertiaryColor;
$CC-banner-zeroData__bg: $white;
$CC-banner-zeroData__txtColor: #39b54a;
$CC-banner-blackFriday__bg: #222;
$CC-banner-blackFriday__txtColor: $white;
$CC-banner-blackFriday-prizeMoney__txtColor: $brand-tertiaryColor;
$CC-banner-blackFriday-billionTxt__color: $brand-tertiaryColor;
$CC-boxing-special__bg: #1a0000;
$CC-boxing-special-matchDetails-kickOff__txtColor: #AAA;
$CC-banner-depositBonus__bg: $brand-primaryColor;
$CC-zeroBalance-banner__bg: $brand-primaryColor;

/*----------------------- Footer Variables ---------------------*/
$CC-footer__bg: $black;
$CC-footer__padding: 20px 10px;
$CC-footer__txtColor: #AAA;
$CC-footer__fontSize: 11px;
$CC-footer-divider__bg: #165521;


$CC-mobile-footer__bg: #103218;
$CC-mobile-footer__height: 65px;

$CC-mobile-footer-item__txtColor: #FFF;
$CC-mobile-footer-item__fontSize: 12px;
$CC-mobile-footer-item-active__bg: $brand-secondaryColor;
$CC-mobile-footer-item-active__txtColor: #ffd800;
$CC-mobile-footer-item-icon__fontSize: 24px;

$CC-mobile-footer-bets-count__width: 22px;
$CC-mobile-footer-bets-count__height: 22px;
$CC-mobile-footer-bets-count__bg: $brand-secondaryColor;
$CC-mobile-footer-bets-count__txtColor: $black;
$CC-mobile-footer-chatBubble__bg: $brand-secondaryColor;
$CC-mobile-footer-chatBubble__txtColor: #333;
$CC-mobile-footer-betslip-active__bg: $brand-secondaryColor;
$CC-mobile-footer-betslip-active__txtColor: #000;
$CC-mobile-footer-betslip-active-bets-count__bg: #FFF;
$CC-mobile-footer-betslip-active-bets-count__txtColor: $brand-secondaryColor;



$CC-scrolltoTop__bg: #EEE;
$CC-scrolltoTop__svgFill: $brand-secondaryColor;