.CC-btnPrimary{
    border: 1px solid #ccc;
    font-size: 14px;
    padding: 0 20px;
    color: #eaeaea;
    &.active{
        background-color: #b09a51;
        color: $white;
        border: 1px solid #b09a51;
    }
    &.disabled{
        background-color: #b09a513e;
        color: $white;
        border: 1px solid #b09a513e;
    }
}
.CC-btnSecondary{
    border: 1px solid #ccc;
    font-size: 14px;
    padding: 8px 20px;
    color: #eaeaea;
    &.active{
        background-color: $brand-primaryColor;
        color: $white;
        border: 1px solid $brand-primaryColor;
    }
}

.CC-btnExtraLarge {
    height: $CC-btnExtraLarge__height;
    font-size: 24px;
}

.CC-btnLarge {
    height: $CC-btnLarge__height;
    font-size: $CC-btnLarge__fontSize;
}

.CC-btnMedium {
    height: $CC-btnMedium__height;
    font-size: $CC-btnMedium__fontSize;
}

.CC-btnSmall {
    height: $CC-btnSmall__height;
    font-size: $CC-btnSmall__fontSize;
}

.CC-btnLogout{
    font-weight: 400;
    width: 140px;
    padding-bottom: 2px;
    @media screen and #{$CC-mediaQueryRange-tabletDevice} {
        width: 90px;
        height: $CC-btnSmall__height!important;
        font-size: $CC-btnSmall__fontSize!important;
    }
    @media screen and #{$CC-mediaQueryRange-mobileDevice} {
        width: 90px;
        height: $CC-btnSmall__height!important;
        font-size: $CC-btnSmall__fontSize!important;
    }
}

.CC-btnFullWidth {
    width: 100%;
}
.CC-btnHalfWidth {
    width: 48%;
    &:first-child {
        margin-right: 1%;
    }
    &:last-child {
        margin-left: 1%;
    }
    
}